<div class="row">
   <div class="col-sm-7">
      <h4 style="color: #474747; font-weight: 600">
         <i class="fa fa-wpforms" style="margin-right: 5px"></i> Formularios Disponibles.
      </h4>
      <small class="text-muted">Aquí podra crear y consultar formularios para clasificar llamadas.</small>
   </div>
   <div class="col-sm-5 text-right">
      <button type="button" class="btn" title="Crear nuevo" (click)="modalFormulario()"><i
            class="fa fa-plus-circle"></i> Nuevo</button>
      <div class="dropdown" style="display: inline">
         <button class="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" title="Ordenar"
            aria-haspopup="true" aria-expanded="true">
            <i class="fa fa-sort"></i>
         </button>
         <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1" style="margin-top: 12px">
            <li><a href="javascript: void(0)" (click)="ordernar('fecha')"><i class="fa fa-calendar text-muted"
                     style="margin-right: 10px"></i> Por fecha</a></li>
            <li><a href="javascript: void(0)" (click)="ordernar('titulo')"><i class="fa fa-sort-alpha-asc text-muted"
                     style="margin-right: 10px"></i> Por título</a></li>
         </ul>
      </div>
   </div>
</div>

<div class="row" style="margin-top: 15px;" *ngIf="formasDisponibles$ | async as formasDisponibles; else loading">

   <div class="col-md-3" style="margin-bottom: 10px" *ngFor="let _forma of formasDisponibles">
      <div style="border: 1px solid #e2e2e2">
         <a href="javascript:void(0)" (click)="cargarFormulario(_forma.id)">
            <div class="text-justify" style="background-color: #f2f2f2; padding: 15px">
               <span style="font-weight: 600; font-size: 15px" (click)="cargarFormulario(_forma.id)">{{_forma.titulo |
                  txtLimit:25}}</span>
               <br>
               <span class="text-muted" style="font-size: 12px">{{(_forma?.descripcion || 'Sin descripción') |
                  txtLimit:35}}</span>
               <div class="text-muted" style="font-size: 12px; margin-top: 3px"><i class="fa fa-calendar-check-o"
                     style="color: #cc00cc"></i> {{(_forma.fecha | toFecha)}}</div>
            </div>
         </a>
         <div style="padding: 15px">
            <div class="btn-group" style="margin-right: 5px;">
               <button type="button" (click)="resultados(_forma.id)" class="btn btn-success btn-sm"><i
                     class="fa fa-pie-chart"></i> Resultados</button>
               <button type="button" class="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
               </button>
               <ul class="dropdown-menu">
                  <li><a href="javascript:void(0)" (click)="resultadosLegacy(_forma.id)">Resultados versiones
                        anteriores</a></li>
               </ul>
            </div>
            <button type="button" class="btn-sm btn btn-default" title="Editar formulario"
               (click)="cargarFormulario(_forma.id)">
               <i class="fa fa-pencil"></i> Editar
            </button>
            <button style="float: right;" type="button" class="btn btn-default btn-sm" title="Eliminar formulario"
               (click)="delForma(_forma.id)">
               <span class="glyphicon glyphicon-trash"></span>
            </button>
         </div>
      </div>
   </div>
   <div class="col-xs-12 text-muted text-center" style="padding: 100px" *ngIf="formasDisponibles.length === 0">
      <i class="fa fa-wpforms fa-4x" style="color: #8f8f8f;"></i><br>
      <h5>Aun no hay formularios disponibles. <a href="javascript:void(0)" (click)="modalFormulario()">Crear un nuevo
            formulario.</a></h5>
   </div>
</div>
<ng-template #loading>
   <div class="text-center" style="margin: 70px;">
      <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 15px;"></i><br>
      Cargando formularios...
   </div>
</ng-template>

<div class="modal fade" tabindex="-1" role="dialog" id="tipiForm">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div style="height: 10px; background-color: #009933"></div>
         <div class="modal-body">
            <formulario-tag (isFormInvalid)="isFormInvalid = $event"></formulario-tag>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="isFormInvalid"
               (click)="guardarFormulario()">Guardar Formulario</button>
         </div>
      </div><!-- /.modal-content -->
   </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div class="modal fade" tabindex="-1" role="dialog" id="modalResultados">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div style="height: 10px; background-color: #109618"></div>
         <div class="modal-header text-center" style="background-color: #f7f7f7">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
            <h5 class="modal-title text-center" style="font-size: 15px"> <i class="fa fa-pie-chart"></i> Resultados</h5>
         </div>
         <div class="modal-body">
            <resumen-tipi [idFormulario]="_idFormulario"></resumen-tipi>
         </div>
      </div><!-- /.modal-content -->
   </div><!-- /.modal-dialog -->
</div><!-- /.modal -->